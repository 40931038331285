import { Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Sectionthree() {
    const { t } = useTranslation();
    return (
        <>
            <div style={{
                backgroundColor: "#f8f9ff",
                width: "100%",
                paddingTop: "5rem",
                textAlign: "center",
                paddingBottom: "5rem"

            }}>
                <Container maxWidth="sm">
                    <Grid container justifyContent={"center"} >
                        <Typography sx={{
                            fontSize: { "md": "1.9rem", "xs": "1.2rem" },
                            fontWeight: "bold", textAlign: "center", marginBottom: "0.3rem",
                            color: "#14213D"
                        }} >{t('sectionthreetittle')}</Typography>
                    </Grid>
                </Container>
                <Grid container justifyContent={"center"} >
                    <Container maxWidth="sm">
                        <Typography sx={{
                            textAlign: "center", marginBottom: "1.8rem",
                            color: "#707070"
                        }} > {t('sectionthreesubtittle')}</Typography>
                    </Container>
                </Grid>
                <Grid container justifyContent={"center"} >
                    <img src="./assets/home/oxipos.png" style={{ width: "58%", minWidth: "300px", height: "auto", marginBottom: "3rem" }} alt="recent Project" />
                </Grid>
                <Grid container justifyContent={"center"} spacing={2}>
                    <Grid item>
                        <Link to="https://play.google.com/store/apps/details?id=com.oxidetech.oxipos&hl=en" target="_blank" style={{ textDecoration: "none" }}>
                            <img
                                src={t('playstore')}
                                alt="Get it on Google Play"
                                style={{ height: "38px" }}
                            />
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to="https://apps.apple.com/cz/app/oxipos/id6476778553" target="_blank" style={{ textDecoration: "none" }}>
                            <img
                                src={t('appstore')}
                                alt="Download on the App Store"
                                style={{ height: "40px" }}
                            />
                        </Link>
                    </Grid>
                </Grid>
            </div>
        </>
    )

}