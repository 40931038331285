import { Typography } from "@mui/material";

import PriorityCard from "../components/prioritycard";
import { useTranslation } from 'react-i18next';

export default function HeroSection() {
    const imageURL = "./assets/home/herobg.svg";
    const { t } = useTranslation('HomePage');
    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${imageURL})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    maxHeight: { xs: "35vh", md: "60vh" },
                    width: "100%",
                    paddingTop: "6rem",
                    textAlign: "center",

                }}
            >
                <Typography
                    sx={{
                        fontSize: { "md": "3rem", "xs": "1.9rem" },
                        fontWeight: "bold",
                        color: "#14213D"
                    }}
                >
                    {t('herotittle1')}
                </Typography>

                <Typography
                    sx={{
                        fontSize: { "md": "3rem", "xs": "1.9rem" },
                        fontWeight: "bold",
                        marginBottom: "1rem",
                        color: "#14213D"
                    }}
                >{t('herotittle2')}</Typography>

                <Typography
                    sx={{
                        fontSize: { "md": "1rem", "xs": "0.95rem" },
                        color: "#D08200",
                        paddingInline: "2rem",
                        marginBottom: "2rem",
                    }}
                >
                    {t('subtittle')}
                </Typography>
                <PriorityCard />


            </div>
        </>
    )

}